import {
  Outlet,
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from "../error-page";
import RequireAuth from "./Auth/RequireAuth";
import CreateCasePage from "src/Pages/CreateCase/CreateCasePage";
import SelectMissionPage from "src/Pages/SelectMission/SelectMissionPage";
import AddMissionPage from "src/Pages/AddMission/AddMissionPage";
import CurrentMissionContainer from "./CurrentMissionContainer/CurrentMissionContainer";
import LoginPage from "src/Pages/Auth/Login/LoginPage";
import CurrentMission from "src/Pages/CurrentMission/CurrentMission";
import AppContainer from "./AppContainer/AppContainer";
import SearchFilePageV2 from "src/Pages/SearchFileV2/SearchFilePage";
import routerSelfCare from "./routerSelfCare";
import { RegistrationPage } from "src/Pages/Auth/OryAuth/RegistrationPage";
import AuthPageContainer from "src/Pages/Auth/AuthPageContainer";
import { RecoveryPage } from "src/Pages/Auth/OryAuth/RecoveryPage";
import SearchMissionsPage from "src/Pages/SearchMissions/SearchMissionsPage";
import SettingsPage from "src/Pages/Settings/SettingsPage";

const router = createBrowserRouter([
  routerSelfCare,
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <AppContainer />,
    children: [
      {
        path: "/",
        element: <AuthPageContainer />,
        children: [
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/registration",
            element: <RegistrationPage />
          },
          {
            path: '/recovery',
            element: <RecoveryPage />
          }
        ]
      },

      {
        path: "/",
        element: (
          <RequireAuth><Outlet /></RequireAuth>
        ),
        children: [
          {
            index: true,
            path: '/cases',
            element: (<SearchFilePageV2 />),
          },
          {
            path: "/settings",
            element: (<SettingsPage />),
          },
          {
            index: true,
            element: (<SearchMissionsPage />),
          },
          {
            path: "/create-case",
            element: (<CreateCasePage />),
          },
          {
            path: "/cases/:caseId",
            element: (<SelectMissionPage />),
          },
          {
            path: "/cases/:caseId/create-mission",
            element: (<AddMissionPage />),
          },
          {
            path: "/cases/:caseId/missions/:missionId",
            element: <CurrentMissionContainer />,
            children: [
              {
                index: true,
                element: (<CurrentMission />),
              }
            ]
          }
        ]
      },

    ],
  }

]);

export default router;