import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCurrentMission } from "src/Stores/CurrentMission";

export default function usePassengers({
  forceReadOnly,
  copyFirstPassengerToPassenger = (_passengerId) => false
}: {
  forceReadOnly?: boolean;
  copyFirstPassengerToPassenger?: (passengerId: string) => boolean;
} = {}) {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<{
    [key: string]: string;
  }>({});
  const { missionContext, setMissionContext, callAction, isStepReadOnly } = useCurrentMission();
  const travelerForm = missionContext?.steps.travelerForm;

  const stepIsReadOnly = forceReadOnly !== undefined ? forceReadOnly : isStepReadOnly('travelerForm');

  const defaultValues: any = {};
  travelerForm?.passengerInformation.forEach((passenger) => {
    (passenger.fields || []).flat().forEach((fieldGroup) => {
      if (fieldGroup.type === 'cards') {
        Object.keys(fieldGroup.cards).forEach((cardId) => {
          defaultValues[passenger.id + "_" + fieldGroup.name + "_" + cardId] = fieldGroup.cards[cardId].value;
        });
        return;
      }
      defaultValues[passenger.id + "_" + fieldGroup.name] = fieldGroup.value;
    })
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {
      errors,
    },

  } = useForm({
    defaultValues,
  });

  const mailEmailInputName = useMemo(() => {
    return (travelerForm?.passengerInformation.length || 0) > 0 ? travelerForm?.passengerInformation[0].id + "_email" : "";
  }, [travelerForm?.passengerInformation]);
  const mainEmail = watch(mailEmailInputName);

  useEffect(() => {
    if (travelerForm?.passengerInformation) {
      travelerForm.passengerInformation.forEach((passenger) => {
        const inputName = passenger.id + "_email";
        if (inputName !== mailEmailInputName) {
          setValue(inputName, mainEmail);
        }
      });
    }
  }, [mainEmail, mailEmailInputName, travelerForm, setValue]);

  const onSubmit = async (data: any) => {

    if (stepIsReadOnly) {
      return;
    }

    const dataForm: any = {
      travelers: [],
    };
    const travelers: any = {};
    if (!travelerForm?.passengerInformation || travelerForm.passengerInformation.length === 0) {
      return false;
    }
    const passengerInformation = travelerForm.passengerInformation;
    const firstPassengerId = passengerInformation[0].id;

    Object.keys(data).forEach((key) => {
      const [passengerId, inputName, inputId] = key.split("_");
      if (!travelers[passengerId]) {
        travelers[passengerId] = {};
      }
      const passenger = passengerInformation.find((passenger) => passenger.id === passengerId);
      const passengerField = (passenger?.fields || []).flat().find((fieldGroup) => {
        if (fieldGroup.name === key.replace(passengerId + "_", "")) {
          return true;
        }

        return false;
      });

      if (inputName === "cards") {
        if (!travelers[passengerId]["cards"]) {
          travelers[passengerId]["cards"] = {};
        }
        travelers[passengerId]["cards"][inputId] = {
          "number": data[key]
        }
      } else if (passengerField) {
        travelers[passengerId][inputName] = data[key];
      }
    });

    if (copyFirstPassengerToPassenger) {
      Object.keys(travelers).forEach((key) => {

        const travelerId = key;
        if (!copyFirstPassengerToPassenger(travelerId)) {
          return;
        }

        travelers[key].email = travelers[firstPassengerId].email;
        travelers[key].phone = travelers[firstPassengerId].phone;
      });
    }

    Object.keys(travelers).forEach((key) => {
      dataForm.travelers.push(travelers[key]);
    });

    setLoading(true);
    try {
      // const response = await authenticatedApi.request({
      //   method: missionContext?.steps.travelerForm?.submitAction?.method,
      //   url: missionContext?.steps.travelerForm?.submitAction?.href,
      //   data: {
      //     ...dataForm,
      //     comments,
      //   },
      // });

      const response = await callAction(travelerForm.submitAction, {
        ...dataForm,
        comments,
      });

      if (response.data?.context) {
        setMissionContext(response.data.context);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    missionContext,
    control,
    handleSubmit,
    errors,
    loading,
    onSubmit,
    comments,
    setComments,

    travelerForm,
    watch,
    stepIsReadOnly,
  }
}