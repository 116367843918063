import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export const useAuthStore = create<{
  access_token: string | null;
  logoutLink: string | null;
  ory_kratos_session: any;

  isAuthenticatedAgent: boolean;
  isAuthenticatedSelfCareKratos: boolean;

  setOryKratosSession: (ory_kratos_session: any) => void;
  setLogoutLink: (logoutLink: string | null) => void;
  isAuthenticated: () => boolean;
  logout: () => void;

  cleanup: () => void;

  setAuthenticatedAgent: (value: boolean) => void;
  setAuthenticatedSelfCareKratos: (value: boolean) => void;

}>()(
  devtools(
    (set, get) => ({
      access_token: null,
      logoutLink: null,
      ory_kratos_session: null,

      isAuthenticatedAgent: false,
      isAuthenticatedSelfCareKratos: false,

      setAuthenticatedAgent: (value: boolean) => set({ isAuthenticatedAgent: value }),
      setAuthenticatedSelfCareKratos: (value: boolean) => set({ isAuthenticatedSelfCareKratos: value }),

      setOryKratosSession: (ory_kratos_session: any) => set({ ory_kratos_session }),
      isAuthenticated: () => {
        return !!get().ory_kratos_session
      },
      setLogoutLink: (logoutLink: string | null) => set({ logoutLink }),
      logout: () => {
        const logoutLink = get().logoutLink;
        get().cleanup();
        if (logoutLink) {
          window.location.href = logoutLink;
        }
      },

      cleanup: () => {
        set({
          access_token: null,
          ory_kratos_session: null,
          isAuthenticatedAgent: false,
          isAuthenticatedSelfCareKratos: false,
        });
      },
    })
  )
);

export function isLogged() {
  return useAuthStore.getState().isAuthenticated();
}
// eslint-disable-next-line
// @ts-ignore
window.getAuthStoreState = useAuthStore.getState;