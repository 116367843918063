import { IconExit, IconSettings } from '@assets/icons/icons';
import styles from './ContextMenu.module.scss';
import { useAuthStore } from 'src/Stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'src/Hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';


export default function ContextMenu({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const { logout } = useAuthStore();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.agentNameEmailContainer}>
        <div className={styles.agentName}>
          {user?.firstName?.substring(0,1) || ""}.{user?.lastName || ""}
        </div>
        <div className={styles.agentEmail}>
          {user?.email}
        </div>
      </div>


      <div className={styles.contextMenuItemsContainer}>

        <div className={styles.contextMenuItem} onClick={() => navigate("/settings")}>
          <IconSettings className={styles.contextMenuItemIcon}/>
          <div className={styles.contextMenuItemText}>
            {t('settings')}
          </div>
        </div>

        <div className={styles.contextMenuItem} onClick={() => logout()} data-testid="logout-link">
          <IconExit className={styles.contextMenuItemIcon}/>
          <div className={styles.contextMenuItemText}>
            {t('logout')}
          </div>
        </div>

      </div>
    </div>
  )
}