import { useAuthStore } from "src/Stores/AuthStore";

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export default function useCurrentUser(): User | null | undefined {

  const { ory_kratos_session } = useAuthStore();

  if (!ory_kratos_session) {
    return null;
  }

  const user: User = {
    id: ory_kratos_session.identity?.id,
    firstName: ory_kratos_session.identity?.traits?.name?.first,
    lastName: ory_kratos_session.identity?.traits?.name?.last,
    email: ory_kratos_session.identity?.traits?.email,
  };

  return user;
}