import { Configuration, FrontendApi } from "@ory/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sdkError } from "src/Providers/ory/sdk";
import { useAuthStore } from "src/Stores/AuthStore";

const loginOryUrl = window.env?.PUBLIC_KRATOS_LOGIN_URL || "";
const sdk = new FrontendApi(
  new Configuration({
    basePath: loginOryUrl || "",
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export function useAuthWithKratos() {

  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const { setLogoutLink, setOryKratosSession, ory_kratos_session, logoutLink} = useAuthStore();

  const navigate = useNavigate();
  const sdkErrorHandler = useMemo(() => sdkError(undefined, undefined, "/login", false, navigate), [navigate]);

  const createLogoutFlow = useCallback(() => {
    // here we create a new logout URL which we can use to log the user out
    sdk
      .createBrowserLogoutFlow(undefined, {
        params: {
          return_to: window.location.origin,
        },
      })
      .then(({ data }) => setLogoutLink(data.logout_url))
      .catch(sdkErrorHandler)
  }, [sdkErrorHandler, setLogoutLink]);

  useEffect(() => {
    // we check if the user is logged in by checking if there is a session
    // if no session is found, we redirect to the login page
    sdk
      .toSession()
      .then(({ data: session }) => {
        // we set the session data which contains the user Identifier and other traits.
        setOryKratosSession(session)
        // Set logout flow
        createLogoutFlow()
        setIsLogged(true)
        setLoading(false)
      })
      .catch(sdkErrorHandler)
      .catch((error) => {
        // Handle all other errors like error.message "network error" if Kratos can not be connected etc.
        if (error.message) {
          return navigate(`/error?error=${encodeURIComponent(error.message)}`, {
            replace: true,
          })
        }

        // Just stringify error and print all data
        navigate(`/error?error=${encodeURIComponent(JSON.stringify(error))}`, {
          replace: true,
        })
      })
  }, [createLogoutFlow, navigate, sdkErrorHandler, setOryKratosSession])

  return {
    orySdk: sdk,
    isLogged,
    loading,
    authData: {
      sessionData: ory_kratos_session,
      logoutUrl: logoutLink
    }
  };
}