import Paper from '@components/Layout/Paper';
import styles from './SelectedTrain.module.scss';
import TravelPath from '@components/TravelPath/TravelPath';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import Button from '@components/Button/Button';
import React, { useState } from 'react';
import Offer from './Offer';
import { isSelfCare } from 'src/Stores/AppContext';
import { TrainProposalJourneyStepType, TrainProposalType } from 'src/Stores/CurrentMissionTypes/Types';
import { useTranslation } from 'react-i18next';

export default function SelectedTrain({
  isLoading,
  readOnly,
  proposal,
  cabinClassIndex,
  offerIndex,
  onClickOffer,
  displayAllCabinClassOffers = false,
  containerProps: { className, ...containerProps } = {},
}: {
  isLoading?: boolean;
  readOnly?: boolean;
  proposal: TrainProposalType;
  cabinClassIndex: number;
  offerIndex: number;
  displayAllCabinClassOffers?: boolean;
  onClickOffer: (offerIndex: number, cabinClassIndex: number) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { mission, setMissionContext, callAction } = useCurrentMission();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const journeySteps = proposal.journeySteps;
  const envelope =  mission?.guaranteeDetails?.isEnvelope && mission.guaranteeDetails.envelope.availableAmount

  const getTotalDuration = (journeyStep: TrainProposalJourneyStepType) =>{
    const fromDateTime = new Date(journeyStep.origin.datetime);
    const toDateTime = new Date(journeyStep.destination.datetime);

    const diff = toDateTime.getTime() - fromDateTime.getTime();
    const durationFormmatted = formatDuration({
      value: diff / 1000 / 60,
      unit: "minutes",
    });
    return durationFormmatted;
  }
  const getConnectionDuration = (indexCurrentStep: number) =>{
    const fromDateTime = new Date(journeySteps[indexCurrentStep].destination.datetime);
    const toDateTime = new Date(journeySteps[indexCurrentStep + 1].origin.datetime);

    const diff = toDateTime.getTime() - fromDateTime.getTime();
    const durationFormmatted = formatDuration({
      value: diff / 1000 / 60,
      unit: "minutes",
    });
    return durationFormmatted;
  }

  const onClickValidate = async () => {
    const offers = proposal.cabinClasses[cabinClassIndex]?.offers || [];
    if (loading || isLoading || readOnly || !offers[offerIndex]?.actions?.selectOffer?.href) {
      return;
    }

    setLoading(true);
    try {

      const response = await callAction(offers[offerIndex].actions.selectOffer);

      setMissionContext(response.data.context);
      return false;

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Paper className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={styles.date}>
        {format(new Date(proposal.origin.datetime), "PPPP")}
      </div>

      <div className={styles.train}>

        {!isSelfCare() && (
        <div className={styles.carrierImgContainer}>
          {!!proposal.carrier?.logoUrl && (
            <img src={proposal.carrier.logoUrl} width={40} />
          )}
        </div>
        )}

        <div className={styles.journeySteps}>
        {
          journeySteps.map((journeyStep, index) => (
            <React.Fragment key={index}>
              <TravelPath
                travelInfo={{
                  left: getTotalDuration(journeyStep),
                  right: isSelfCare() && journeySteps.length === 1 ? (
                    proposal.connectionsCount === 0 ? t('direct_connection') : t('connections_count', { count: proposal.connectionsCount })
                  ) : (
                    journeyStep.transport.type + " " + journeyStep.transport.number
                  )
                }}
                from={{
                  labelLeft: format(new Date(journeyStep.origin.datetime), "HH:mm"),
                  labelRight: journeyStep.origin.name,
                }}
                to={{
                  labelLeft: format(new Date(journeyStep.destination.datetime), "HH:mm"),
                  labelRight: journeyStep.destination.name,
                }}
                containerProps={{
                  className: styles.travelPath,
                }}
              />

              {proposal.connectionsCount > 0 &&
                index < journeySteps.length - 1 && (
                  <p className={styles.connection}>
                    {t('correspondence_duration', { duration: getConnectionDuration(index) })}
                  </p>
                )}
            </React.Fragment>
          ))
        }
        </div>

      </div>

      {(
        displayAllCabinClassOffers ? proposal.cabinClasses : [proposal.cabinClasses[cabinClassIndex]]
      ).map((cabinClass, i) => cabinClass?.offers.map((offer, j) => (
        <Offer
          selected={cabinClassIndex === i && offerIndex === j}
          readOnly={readOnly}
          onClickOffer={() => {
            onClickOffer(j, i);
          }}
          containerProps={{
            className: styles.selectableContent,
          }}
          key={offer.id}
          envelope={envelope || undefined}
          offer={offer}
        />
      )))}

      {!readOnly && (
      <div className="w-full">
        <Button
          testid='button-validate-trainselection'
          label={t('validate')}
          onClick={onClickValidate}

          containerProps={{
            className: "w-full",
            disabled: loading || isLoading || readOnly || !proposal.cabinClasses[cabinClassIndex]?.offers[offerIndex]?.actions?.selectOffer?.href,
          }}
        />
      </div>
      )}

    </Paper>
  )
}