import React from 'react';
import styles from './FilterButton.module.scss';
import { Filter } from '@assets/icons/icons';

export default function FilterButton({
  onClick,
  countNbFiltersApplied,
  layout = 'default',
  containerProps: { className, ...containerProps } = {},
}: {
  onClick?: () => any;
  countNbFiltersApplied: number;
  layout?: 'default' | 'outlined';
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  return (
    <div className={[styles.container, styles[`layout_${layout}`], className].join(" ")} {...containerProps} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
      <Filter />
      <div className={styles.label}>Filtres</div>
      <div className={styles.badge}>
        <div className={styles.badgeText}>{countNbFiltersApplied}</div>
      </div>
    </div>
  )
}