import DotsLine from '@components/DotsLine/DotsLine';
import styles from './ItineraryStep.module.scss';
import { getTransportationIcon, formatDistance } from './helpers';
import { transportationTypes } from './types';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import { PlaceType } from 'src/models/Place';
import { PriceType } from 'src/models/Price';
import { RentalTypeInSolution } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import VRentalSteps from './VRentalSteps/VrentalSteps';


export type ItineraryStepProps = {
  type: transportationTypes;
  origin?: PlaceType;
  destination?: PlaceType;
  kgCO2e?: number;
  distanceInMeters?: number;
  displayDepartureTime?: boolean;
  displayArrivalTime?: boolean;
  options: Array<{
    price: PriceType;
    durationInMinutes?: number;
    departureTime?: string;
    arrivalTime?: string;
  }>;
  vrentals?: Array<RentalTypeInSolution>;
};

export default function ItineraryStep({
  type,
  origin,
  destination,
  options,
  vrentals,
  displayArrivalTime,
  displayDepartureTime,
  distanceInMeters,
  showRemainingCosts,
  containerProps: { className, ...containerProps } = {},
}: {
  showRemainingCosts: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
} & ItineraryStepProps) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.header}>
        {origin && (
          <div className={styles.headerText} title={origin?.name}>
            {origin?.name}
          </div>
        )}

        {getTransportationIcon(type)}

        {destination && (
          <div className={`${styles.headerText} ${styles.headerTextRight}`} title={destination?.name}>
            {destination?.name}
          </div>
        )}

      </div>

      <div className={styles.optionsContainer}>
        {!vrentals && options.map((option, i) => {

          const departureDateTime = new Date(option.departureTime || "");
          const arrivalDateTime = new Date(option.arrivalTime || "");

          return (
            <div key={i} className={styles.optionContainer}>
                <DotsLine
                  dots={[
                    (displayDepartureTime !== false && option.departureTime) ? {
                      label: format(departureDateTime, "HH:mm")
                    } : {},
                    {
                      tag: (option.price.amount > 0 ? option.price?.formatted + " " + option.price?.currency.symbol : distanceInMeters ? formatDistance(distanceInMeters) : "") || "",
                      tagStyle: 'outlined',
                      label: option.durationInMinutes ? formatDuration({
                        value: option.durationInMinutes,
                        unit: 'minutes'
                      }) : undefined,
                    },
                    (displayArrivalTime !== false && option.arrivalTime) ? {
                      label: format(arrivalDateTime, "HH:mm")
                    } : {},
                  ]}
                />
            </div>
          )
        })}

        {vrentals && (
          <VRentalSteps
            vrentals={vrentals}
            showRemainingCosts={showRemainingCosts}
            containerProps={{
              className: styles.vrentalContainer,
            }}
          />
        )}
      </div>

    </div>
  )
}