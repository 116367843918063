import { TravelerFormRecapType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import styles from './Recap.module.scss';
import TravelPath from '@components/TravelPath/TravelPath';
import formatDuration from 'src/helpers/duration';
import { getTransportationIcon, getTransportationLabel } from '@components/Itinerary/helpers';
import { IconChevronSelfCare } from '@assets/icons/icons';
import React from 'react';

export default function Recap({
  recap,
  containerProps = {},
}: {
  recap: TravelerFormRecapType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const departureTime = new Date(recap.departureTime);
  const arrivalTime = new Date(recap.arrivalTime);
  const durationInMinutes = (arrivalTime.getTime() - departureTime.getTime()) / 1000 / 60;

  const durationFormatted = formatDuration({ unit: 'minutes', value: durationInMinutes });

  return (
    <TravelPath
      travelInfo={{
        left: durationFormatted,
        right: (
          <div className={styles.travelPathRight}>
            {recap.sections.map((section, i) => (
              <React.Fragment key={i}>
                {i === 0 ? null : (
                  <div className={styles.iconSeparatorContainer}>
                    <IconChevronSelfCare className={styles.iconSeparator} />
                  </div>
                )}
                <div className={`${styles.travelPathSection} ${section.isMainTransport ? '' : styles.notMainTransport}`}>
                  {getTransportationIcon(section.type)}
                  <div className={styles.travelPathSectionLabel}>
                    {getTransportationLabel(section.type)}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )
      }}
      from={{
        labelLeft: departureTime.toLocaleTimeString().slice(0, 5),
        labelRight: recap.origin.name
      }}
      to={{
        labelLeft: arrivalTime.toLocaleTimeString().slice(0, 5),
        labelRight: recap.destination.name
      }}
      containerProps={containerProps}
    />
  )
}