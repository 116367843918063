import styles from './DotsLine.module.scss';

export default function DotsLine({
  dots,
  containerProps: { className, children: _children, ...containerProps } = {},
}: {
  dots: Array<{
    label?: string;
    tag?: string;
    tagStyle?: 'contained' | 'outlined';
  }>;

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={styles.lineContainer}>
        <div className={styles.line} />
      </div>

      <div className={styles.contentContainer}>

        {dots.map((dot, i) => (
          <div key={i} className={[
            styles.dotContainer,
            i === 0 ? styles.dotContainerLeft : "",
            i > 1 && i === dots.length - 1 ? styles.dotContainerRight : "",
          ].join(" ")}>
            {dot.tag ? (
              <div className={[styles.tag, dot.tagStyle === 'outlined' ? styles.tagOutlined : ""].join(" ")}>
                {dot.tag}
              </div>
            ) : (
              <div className={styles.dot} />
            )}
            <div className={styles.dotLabel}>{dot.label}</div>
          </div>
        ))}

      </div>
    </div>
  )
}